<template>
        <div class="col-lg-12">
         <div id="post-modal-data" class="card card-block card-stretch">
            <div class="card-header d-flex justify-content-between">
               <div class="header-title">
                  <h4 class="card-title">What is going on?</h4>
               </div>
            </div>
            <div class="card-body">                                 
                  <div class="d-flex align-items-center">
                     <div class="user-img">
                        <img :src="avatar" alt="userimg" class="avatar-60 rounded-circle">                  
                     </div>
                     <div id='app_post_message' class='demo' contenteditable='true'></div>                                       
                     <!--<form class="post-text ms-3 w-100 "  data-bs-toggle="modal" data-bs-target="#post-modal" action="javascript:void();">
                        <input type="text" class="form-control rounded" placeholder="Write something here..." style="border:none;" onclick="getFocus()">
                     </form>
                     -->
                     <div id="post_loader"><img src="https://tshaku.com/view/socialV/assets/images/page-img/page-load-loader.gif"/></div>
                  </div>
                  <hr>
                  <div class="d-flex" id="app_post_preview">
                     <div id="app_post_preview_toolbar" class="row">
                        <a href="#" @click="clear_preview_box()">[X]</a>
                     </div>
                     <div id="app_post_preview_body" class="row" style="text-align:center;width:100%;">  
                        <img v-if="photourl" :src="photourl" style="margin-bottom:10px;"/>   
                        <video v-else-if="videourl" controls="" style="width:100%;margin:bottom:10px;">
                           <source :src="videourl">
                           Your browser does not support HTML5 video.
                        </video>
                     </div>
                  </div>
                  <div class="progress" id="progress_bar" style="display:none;"></div>
                  <div id="result"></div>
                  <ul class=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                     <li class="me-3 mb-md-0 mb-2">
                        <button class="btn btn-soft-primary" id="app_post_button" @click="post_message()">
                           Post Now
                        </button>   
                        <input type="hidden" id="app_platform_org_id" value=">"/>                                 
                     </li>
                     <li class="me-3 mb-md-0 mb-2">                           
                        <a href="#" class="btn btn-soft-primary" id="upload_link" @click="$refs.photo.click()">
                           <img src="https://tshaku.com/view/socialV/assets/images/small/07.png" alt="icon" class="img-fluid me-2"> Photo
                           <input class="file-upload" type="file" accept="image/*" v-on:change="loadPhoto" name="photo" ref="photo" style="display:none;"/>				
                        </a>
                     </li>
                     <li class="me-3">                                                
                        <a href="#" class="btn btn-soft-primary" id="upload_video" @click="$refs.vid.click()">
                           <img src="https://tshaku.com/view/socialV/assets/images/small/13.png" alt="icon" class="img-fluid me-2"> Video
                           <input class="file-upload" type="file" accept="video/mp4" v-on:change="loadVideo" name="vid" ref="vid" style="display:none;"/>
                        </a>
                     </li>
                     <li>
                        <button class="btn btn-soft-primary">
                           <div class="card-header-toolbar d-flex align-items-center">
                              <div class="dropdown">
                                 <div class="dropdown-toggle" id="post-option"   data-bs-toggle="dropdown">
                                    <i class="ri-more-fill"></i>
                                 </div>
                                 <div class="dropdown-menu dropdown-menu-right" aria-labelledby="post-option" style="">
                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#post-modal">Check in</a>
                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#post-modal">Live Video</a>
                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#post-modal">Gif</a>
                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#post-modal">Watch Party</a>
                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#post-modal">Play with Friend</a>
                                 </div>
                              </div>
                           </div>
                        </button>
                     </li>
                  </ul>                              
            </div>
            <div class="modal fade" id="post-modal" tabindex="-1"  aria-labelledby="post-modalLabel" aria-hidden="true" >
               <div class="modal-dialog   modal-fullscreen-sm-down">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="post-modalLabel">Create Post</h5>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="ri-close-fill"></i></button>
                     </div>
                     <div class="modal-body" id="app_modal_body">

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
</template>

<script>
import axios from 'axios'
export default {    
   data(){
     return{
         org_id:37,
         photo:'',
         photourl:'',
         video:'',
         videourl:'',
         post_type:''

     }
   },
   computed:{
      avatar(){
         return this.$store.state.avatar
      }
   },
   props:[],
   setup(){
      
   },
   methods:{      
      clear_preview_box(){            
         this.video=''
         this.photo=''
         this.photourl=''
         this.videourl=''
         this.post_type=''
         document.getElementById('app_post_preview_toolbar').style.display='none'
      },  
      loadPhoto(e){
         this.videourl=''  
         var image = e.target.files[0]
         if(image){
            this.photo = image
            this.photourl = URL.createObjectURL(image);
            this.post_type='media_photo'
            document.getElementById('app_post_preview_toolbar').style.display='block'
         }         

      }, 
      loadVideo(e){
         this.photourl=''
         var video = e.target.files[0]         
         if(video){
            this.video = video            
            this.videourl = URL.createObjectURL(video)
            this.post_type='media_video'
            document.getElementById('app_post_preview_toolbar').style.display='block'
         }         
      },
      async post_message(){  
         var message=document.getElementById("app_post_message").innerHTML;
         if(!message){
            alert('Your message is empty, please say something :)')
         }
         else{
            var form = new FormData();
            if(this.photo){                    
               form.append('photos',this.photo)
               form.append('photo_desc',message)
            }
            else if(this.video){
               form.append('files',this.video)
               form.append('video_desc',message)
            }
            else{
               form.append('text',message)
            }            
            form.append('org_id',this.org_id)            
            form.append('action','add')            
            let conf = {
               header : {
                  'Content-Type' : 'multipart/form-data'
               }
            }                      
            const response = await axios.post('post.php',form,conf)
            //Let do some validations here
            //End let do some validations here
            if(response.status==200){
               //There are no communication issue with the endpoint
               const data=response.data
               if(data.response_code==200){
                  //The request has worked, redirect user to the login page
                  alert('Your message has been posted')                  
               }
               else{
                  console.log(data)
                  alert('Error posting message :'+data.response_desc)
               }
            }
            else{
               alert('An error occured during data submission, please contact support')
            }
         }
      }
   }   
}
</script>

<style>
    .demo{
         width:100%;
         height:auto;
         min-height:100px;
         border:none;
         color:#FFF;
         padding:10px;
         overflow-y:scroll;
      }
      .demo img{
         width:60%;
         overflow:hidden;
      }
      #post_loader{
         display:none;
         position:absolute;
         top:-100px;
         left:0;
         width:100%;
      }
      #app_post_preview{         
         text-align:center;
         display:none;
      }      
      #app_post_preview_toolbar{           
         text-align:right;
         display:none;
         position:relative;
         top:0%;
         left:90%;
         font-weight:bold;
         z-index:9999;
      }
      #app_post_preview_body{

      }
      #photo_upload_button{
         display:none;
      }
</style>
   
