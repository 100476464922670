import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import ExplorerView from '../views/Explorer.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Profile from '../views/Profile.vue'
import Search from '../views/Search.vue'
import EditProfile from '../views/EditProfile.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true // Add meta field to indicate protected route
    }
  },
  {
    path: '/explorer',
    name: 'explorer',
    component: ExplorerView,
    meta: {
      requiresAuth: true // Add meta field to indicate protected route
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path:'/register',
    name: 'register',
    component: Register
  },
  {
    path: '/profile/edit',
    name: 'profile edit',
    component: EditProfile,
    props:true,
    meta: {            
      requiresAuth: true // Add meta field to indicate protected route
    }
  },  
  {
    path: '/profile/:id',
    name: 'profile',
    component: Profile,
    props:true,
    meta: {      
      watchParam: 'id',
      requiresAuth: false // Add meta field to indicate protected route
    }
  },  
  {
    path: '/search/:q',
    name: 'search',
    component: Search,
    props:true,
    meta: {
      requiresAuth: true // Add meta field to indicate protected route
    }
  }  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = localStorage.getItem('token');
    if (token) {
      // User is authenticated, proceed to the route
      next();
    } else {
      // User is not authenticated, redirect to login
      next('/login');
    }
  } 
  else {
    // Non-protected route, allow access
    next();
  }
});

export default router
